var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"573ed905fde5dce4990cb26b618b8548e9b43f0e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-undef */
import * as Sentry from '@sentry/nextjs'

import { getClientEnvironment, getIgnoreErrors, getSentryConfig } from './sentry-config'

const BREADCRUMBS_ALLOWED_CATEGORIES = ['custom', 'exception', 'navigation']

Sentry.init({
  ...getSentryConfig(),
  environment: getClientEnvironment(),
  ignoreErrors: getIgnoreErrors(),
  beforeBreadcrumb(breadcrumb) {
    return BREADCRUMBS_ALLOWED_CATEGORIES.includes(breadcrumb.category) ? breadcrumb : null
  },
})

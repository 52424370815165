import { Box, Container, Flex, Image, Link as ChakraLink } from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Divider from '~/components/common/Divider'
import { DOCS_URL } from '~/constants'
import { getInfiniteLogoAnimation } from '~/helpers'
import { useTranslation } from '~/lib/i18n'
import { theme } from '~/styles/theme'
import Card from '../common/Card'
import SocialLinks from './SocialLinks'

const Footer = () => {
  const { t } = useTranslation('layout')
  const { pathname } = useRouter()

  const isHome = pathname === '/'
  const logoIsotypeStyle = getInfiniteLogoAnimation()

  return (
    <Card>
      <Box
        backdropFilter="blur(16px)"
        zIndex={1}
        w="100%"
        as="footer"
        background={theme.colors.background.box}
        borderTop="1px solid #FFFFFF12"
        pos={{ base: 'relative', md: isHome ? 'absolute' : 'relative' }}
        bottom={0}
        mt={isHome ? -2 : 0}
      >
        <Container maxW="container.xl">
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            gap={{ base: '6', md: '0' }}
            alignItems="center"
            justifyContent="space-between"
            py={5}
          >
            <Flex as="nav" flexDir={{ base: 'column', md: 'row' }} alignItems="center">
              <ChakraLink
                as={Link}
                href="/"
                _hover={{ textDecoration: 'none' }}
                mr={{ base: '0', md: '10' }}
                mb={{ base: '6', md: '0' }}
              >
                <Image w={8} h={8} src="/assets/deepr-icon-white.png" style={logoIsotypeStyle} />
              </ChakraLink>
              <Flex
                gap={{ base: '4', md: '12' }}
                pl={{ md: '4' }}
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="center"
              >
                <ChakraLink as={Link} href="/markets" fontSize="sm" color="text.secondary">
                  {t('navigation.markets')}
                </ChakraLink>

                <ChakraLink as={Link} href="/staking" fontSize="sm" color="text.secondary">
                  {t('navigation.staking')}
                </ChakraLink>

                <ChakraLink as={Link} href="/bridge" fontSize="sm" color="text.secondary">
                  {t('navigation.bridge')}
                </ChakraLink>

                <ChakraLink href={DOCS_URL} target="_blank" fontSize="sm" color="text.secondary">
                  {t('navigation.docs')}
                </ChakraLink>

                <ChakraLink as={Link} href="/subies" fontSize="sm" color="text.secondary">
                  {t('navigation.subieMint')}
                </ChakraLink>

                <ChakraLink as={Link} href="/terms" fontSize="sm" color="text.secondary">
                  {t('navigation.terms')}
                </ChakraLink>
              </Flex>
            </Flex>
            <Divider display={{ md: 'none' }} />
            <SocialLinks />
          </Flex>
        </Container>
      </Box>
    </Card>
  )
}

export default Footer

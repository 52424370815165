import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  chakra,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import ClaimModal from '~/components/dashboard/ClaimModal'
import { ButtonSize, ButtonVariant, CustomButton } from '~/components/layout/CustomButton'
import { DOCS_URL, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~/constants'
import { networkIcons } from '~/constants/networkImages'
import { getInfiniteLogoAnimation } from '~/helpers'
import { disabledFeatures } from '~/helpers/env'
import { useDistributionToken } from '~/hooks/useDistributionToken'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'
import useSupportedNetworkId from '~/hooks/useSupportedNetworkId'
import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { theme } from '~/styles/theme'
import NetworkModal from '../dashboard/NetworkModal'
import { CustomConnectKitButton } from './CustomConnectKitButton'
import HeaderButton from './HeaderButton'

interface Props {
  isOpenDrawer: boolean
  onOpenDrawer: () => void
  onCloseDrawer: () => void
}

const Header = ({ isOpenDrawer, onOpenDrawer, onCloseDrawer }: Props) => {
  const { t } = useTranslation('layout')
  const { pathname } = useRouter()

  const { distributionToken } = useDistributionToken()
  const chainId = useSupportedNetworkId()

  const {
    isOpen: isOpenDistributionModal,
    onOpen: onOpenDistributionModal,
    onClose: onCloseDistributionModal,
  } = useDisclosure()

  const { isOpen: isOpenNetworkModal, onOpen: onOpenNetworkModal, onClose: onCloseNetworkModal } = useDisclosure()

  const isClaimDeeprDisabled = disabledFeatures.protocol?.claimDeepr?.disabled || disabledFeatures?.protocol?.disabled

  const openClaimModal = () => {
    onOpenDistributionModal()
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_MODAL_OPENED)
  }

  const closeClaimModal = () => {
    onCloseDistributionModal()
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_MODAL_CLOSED)
  }

  const { isConnected } = useHydratedAccount()

  const isHome = pathname === '/'

  const logoIsotypeStyle = getInfiniteLogoAnimation()

  return (
    <chakra.header zIndex={isOpenDrawer ? 'auto' : '1'} position="relative">
      <Flex height={{ base: MOBILE_HEADER_HEIGHT, md: HEADER_HEIGHT }} alignItems="center">
        <Container maxW="container.xl">
          <Flex as="nav" alignItems="center" justifyContent="space-between" py={{ base: 0, md: 7 }}>
            <ChakraLink
              as={Link}
              href={isHome ? '/' : '/dashboard'}
              _hover={{ textDecoration: 'none' }}
              onClick={onCloseDrawer}
            >
              <Flex>
                <Image w="38px" h="38px" src="/assets/deepr-icon-white.png" style={logoIsotypeStyle} zIndex="popover" />
                <Image w="94px" h="38px" src="/assets/logo-text.png" zIndex="popover" />
              </Flex>
            </ChakraLink>
            <HStack spacing="4" alignItems="center" display={{ base: 'none', lg: 'flex' }}>
              <ChakraLink as={Link} href="/markets" paddingX={6} fontWeight="600">
                {t('navigation.markets')}
              </ChakraLink>

              <ChakraLink href="/staking" paddingX={6} fontWeight="600">
                {t('navigation.staking')}
              </ChakraLink>

              <ChakraLink href="/bridge" paddingX={6} fontWeight="600">
                <Flex gap={1} alignItems="center">
                  {t('navigation.bridge')}
                </Flex>
              </ChakraLink>

              {!isHome ? (
                <>
                  {isConnected && (
                    <>
                      <HeaderButton onClick={onOpenNetworkModal} p={2}>
                        <Image boxSize="24px" src={networkIcons[chainId].transparent} />
                      </HeaderButton>
                      <HeaderButton
                        leftIcon={<Image src="/assets/deepr-icon.png" />}
                        onClick={openClaimModal}
                        isDisabled={isClaimDeeprDisabled}
                      >
                        {distributionToken?.totalBalance?.toFixed(4)}
                      </HeaderButton>
                    </>
                  )}
                  <CustomConnectKitButton />
                </>
              ) : (
                <ChakraLink as={Link} href="/dashboard" _hover={{ textDecoration: 'none' }}>
                  <CustomButton variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
                    {t('navigation.launchApp')}
                  </CustomButton>
                </ChakraLink>
              )}
            </HStack>
            <HStack spacing="12px" display={{ base: 'flex', lg: 'none' }}>
              {isConnected && !isHome && (
                <HeaderButton
                  leftIcon={<Image src="/assets/deepr-icon.png" />}
                  onClick={openClaimModal}
                  height="32px"
                  fontSize="14px"
                  lineHeight="16px"
                  p="12px"
                  isDisabled={isClaimDeeprDisabled}
                >
                  <Text maxW="74px" overflowX="clip" textOverflow="ellipsis">
                    {distributionToken?.totalBalance?.toFixed(4)}
                  </Text>
                </HeaderButton>
              )}

              <IconButton
                size="sm"
                icon={
                  isOpenDrawer ? (
                    <CloseIcon boxSize={2.5} color={theme.colors.text.secondary} />
                  ) : (
                    <HamburgerIcon boxSize={6} color={theme.colors.text.secondary} />
                  )
                }
                aria-label={isOpenDrawer ? 'Close Menu' : 'Open Menu'} // TODO: Move to i18n
                onClick={isOpenDrawer ? onCloseDrawer : onOpenDrawer}
                background={theme.colors.background.box}
                zIndex="popover"
                borderWidth={isOpenDrawer ? 0 : 1}
                p={1}
                height="32px"
                borderRadius="10px"
                _hover={{}}
              />
            </HStack>
          </Flex>
        </Container>
      </Flex>

      <ClaimModal isOpen={isOpenDistributionModal} onClose={closeClaimModal} />

      <NetworkModal isOpen={isOpenNetworkModal} onClose={onCloseNetworkModal} />

      <Drawer isOpen={isOpenDrawer} onClose={onCloseDrawer} size="full" placement="top">
        <DrawerOverlay />
        <DrawerContent h="full" bgColor="black">
          <DrawerBody background="radial-gradient(50% 50% at 50% 50%, rgba(6, 21, 55, 0.8) 0%, rgba(3, 10, 26, 0.8) 100%)">
            <VStack spacing={6} h="100%" justify="center">
              <ChakraLink as={Link} href="/markets" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.markets')}
                </Text>
              </ChakraLink>

              <ChakraLink as={Link} href="/staking" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.staking')}
                </Text>
              </ChakraLink>

              <ChakraLink as={Link} href="/bridge" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.bridge')}
                </Text>
              </ChakraLink>

              <ChakraLink href={DOCS_URL} target="_blank" onClick={onCloseDrawer}>
                <Text fontWeight="700" fontSize="xl">
                  {t('navigation.docs')}
                </Text>
              </ChakraLink>

              {!isHome && (
                <Flex direction="column" position="absolute" bottom="24px" w={isConnected ? 'auto' : '342px'} gap={4}>
                  <CustomConnectKitButton />
                </Flex>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </chakra.header>
  )
}

export default Header
